<template>
  <div>
    <el-row style="margin-top: 20px">
      <el-form :inline="true" :model="queryForm">
        <el-form-item label="查询" class="formItemBoxStyle">
          <el-input
            v-model="queryForm.batchNumString"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="uploadVideoData">查询</el-button>
        <el-button type="primary" @click="exportBtn">批量导出</el-button>
        <el-button type="primary" @click="exportTimeNumBtn"
          >批量导出全部</el-button
        >
        <el-button type="primary" @click="exportVideoBtn"
          >批量视频导出</el-button
        >
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-row>
      <el-table
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="tableDataLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column
          type="index"
          align="center"
          width="55"
          label="序号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="studentCode"
          label="学号"
          min-width="140"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="experimentResultCode"
          label="实验记录唯一编码"
          min-width="240"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="aiOperationTestCode"
          label="实验编码"
          min-width="180"
        >
          <template slot-scope="scope">{{
            scope.row.aiOperationTestCode
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="aiOperationTestName"
          label="实验名称"
          min-width="340"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="aiResultTime"
          label="评分提交时间"
          show-overflow-tooltip
          width="180px"
        >
          <template slot-scope="scope">{{
            scope.row.aiResultTime | dayList
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="resultConfidence"
          label="置信"
          width="70"
        >
          <template slot-scope="resultConfidence">
            <span
              :style="
                resultConfidence.row.resultConfidence
                  ? 'color:#67C23A'
                  : 'color:#E6A33F'
              "
              >{{ resultConfidence.row.resultConfidence ? '是' : '否' }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="aiScoreFull"
          label="总分"
          width="60"
        >
        </el-table-column>
        <el-table-column align="center" prop="aiScore" label="得分" width="60">
          <template slot-scope="aiScore">
            <span
              :style="
                aiScore.row.aiScoreFull > aiScore.row.aiScore
                  ? 'color:#E6A33F'
                  : ''
              "
              >{{ aiScore.row.aiScore }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog title="提示" :visible.sync="dialogVisibleTime" width="30%">
      选择时间
      <el-date-picker
        style="width: 100%"
        v-model="exportTime"
        type="datetime"
        value-format="timestamp"
        placeholder="选择日期时间"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTime = false">取 消</el-button>
        <el-button type="primary" @click="exportAIResultTimeNumApiFunc"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import dayjs from 'dayjs'
import { debounce } from '@/utils/util'
import {
  getQuestionResultApi,
  exportAIResultApi,
  exportAIResultTimeNumApi,
  exportVideoApi,
} from '@/api/marking/historyScoreAI.js'
export default {
  name: 'exportHistoryScore',
  data() {
    return {
      queryForm: {
        batchNumString: '',
      },
      tableDataLoading: false,
      tableData: [],
      selectionList: [],
      dialogVisibleTime: false,
      exportTime: '',
    }
  },
  mounted() {
    this.getQuestionResultApiFunc(this.queryForm.batchNumString)
  },
  methods: {
    uploadVideoData: debounce(function () {
      this.getQuestionResultApiFunc(this.queryForm.batchNumString)
    }, 300),

    getQuestionResultApiFunc(query) {
      let formData = new FormData()
      formData.append('batchNumString', query || '')
      this.tableDataLoading = true
      this.tableData = []
      getQuestionResultApi(formData)
        .then((res) => {
          this.tableDataLoading = false
          if (res.success) {
            this.selectionList = []
            this.tableData = res.data
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },

    refresh() {
      this.queryForm.batchNumString = '0'
      this.getQuestionResultApiFunc(this.queryForm.batchNumString)
    },

    handleSelectionChange(val) {
      this.selectionList = val
      console.log('selectionList', this.selectionList)
    },
    // 导出
    exportBtn() {
      if (!this.selectionList || !this.selectionList.length) {
        return this.$message.info('请先勾选')
      }
      let arr = this.selectionList.map((e) => e.aiResultPractiseClassRecordId)
      let fd = new FormData()
      fd.append('idList', arr)
      console.log('arr', arr)
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: '导出中',
      })
      exportAIResultApi(fd).then((res) => {
        console.log('res', res)
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        if (res.success) {
          window.open(this.$imageAddress(res.data))
          this.$message.success('下载成功')
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    // 批量导出全部按钮
    exportTimeNumBtn() {
      this.exportTime = ''
      this.dialogVisibleTime = true
    },
    // 批量视频导出
    exportVideoBtn() {
      let arr = []
      let fd = null
      if (!!this.selectionList.length) {
        arr = this.selectionList.map((e) => e.aiResultPractiseClassRecordId)
        fd = new FormData()
        fd.append('idList', arr)
      }
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: '导出中',
      })
      exportVideoApi(fd)
        .then((res) => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          if (res.success) {
            window.open(this.$imageAddress(res.data))
            this.$message.success('下载成功')
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    exportAIResultTimeNumApiFunc() {
      if (!this.exportTime) {
        return this.$message.info('先选时间')
      }
      let arr = []
      let fd = new FormData()
      if (!!this.selectionList.length) {
        arr = this.selectionList.map((e) => e.aiResultPractiseClassRecordId)
        fd.append('idList', arr)
      }
      fd.append('timeNum', 10)
      fd.append('startTime', this.exportTime)
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: '导出中',
      })
      exportAIResultTimeNumApi(fd)
        .then((res) => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          if (res.success) {
            window.open(this.$imageAddress(res.data))
            this.$message.success('下载成功')
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
  },
  filters: {
    dayList(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
  },
}
</script>

<style></style>
